import React, { useEffect, useState, useRef } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useLongPress, LongPressCallbackReason } from "use-long-press";

export default function Home() {
  const notLeaf = useRef(new Set());
  const [data, setData] = useState([
    // { name: "AAAAA", recommendCode: "A", level: 6, patron: "ROOT" },
    // { name: "BBBBB", recommendCode: "B", level: 5, patron: "A" },
    // { name: "CCCCC", recommendCode: "C", level: 4, patron: "A" },
    // { name: "DDDDD", recommendCode: "D", level: 3, patron: "B" },
    // { name: "EEEEE", recommendCode: "E", level: 2, patron: "B" },
    // { name: "FFFFF", recommendCode: "F", level: 1, patron: "C" },
    // { name: "GGGGG", recommendCode: "G", level: 0, patron: "C" }
  ]);
  const [showChart, setShowChart] = useState(false);
  const [selectedNode, setSelectedNode] = useState();
  // tool
  // const levelColor = [
  //   "bg-[#464f4a]",
  //   "bg-[#464f4a]",
  //   "bg-[#464f4a]",
  //   "bg-[#0a67f2]",
  //   "bg-[#0a67f2]",
  //   "bg-[#b220d6]",
  //   "bg-[#ff5252]"
  // ];
  const levelColor = [
    "bg-[#0a67f2]",
    "bg-[#0a67f2]",
    "bg-[#0a67f2]",
    "bg-[#0a67f2]",
    "bg-[#ff6347]",
    "bg-[#b220d6]",
    "bg-[#ff5252]"
  ];

  const callback = React.useCallback(() => {
    if (selectedNode) {
      postMessage({
        type: "SHOW_CODE",
        node: selectedNode
      });
    }
  }, [selectedNode]);

  const showCode = useLongPress(callback, {
    onStart: (event, meta) => {
      if (meta.context?.recommendCode) setSelectedNode(meta.context);
      else setSelectedNode();
    },
    // onCancel: (event, meta) => {
    //   if (
    //     meta.reason === LongPressCallbackReason.CancelledByRelease &&
    //     meta.context?.recommendCode
    //   ) {
    //     postMessage({ type: "RELOAD", recommendCode: meta.context.recommendCode });
    //   }
    // },
    threshold: 1500
  });

  useEffect(() => {
    // document.body.style.zoom = "50%";
    document.addEventListener("message", (event) => {
      const message = event.data;
      handleMessages(message);
    });

    window.addEventListener("message", (event) => {
      //   alert(JSON.stringify(event, null, 4));
      const message = event.data;
      handleMessages(message);
    });

    return () => {
      document.removeEventListener("message", (event) => {
        const message = event.data;
        handleMessages(message);
      });
      window.removeEventListener("message", (event) => {
        const message = event.data;
        handleMessages(message);
      });
    };
  }, []);

  useEffect(() => {
    // postMessage("data changed.");
    resetScale();
    if (showChart === false) setShowChart(true);
  }, [data]);

  const handleMessages = (message) => {
    try {
      let parsedMessage = JSON.parse(message);
      if (parsedMessage?.type === "ORG_CHART") {
        setShowChart(false);
        notLeaf.current.clear();
        setData(parsedMessage.data);
      }
    } catch (e) {
      return;
    }
  };

  const postMessage = (data) => {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  };

  const resetScale = () => {
    let viewport = document.querySelector("meta[name=viewport]");
    if (!viewport) {
      // in case there is no view port meta tag creates one and add it to the head
      viewport = document.createElement("meta");
      viewport.name = "viewport";
      document.getElementsByTagName("head")[0].appendChild(viewport);
    }

    let div = document.querySelector(".chart");
    let ww = window.screen.width;
    let wh = window.screen.height * 0.7;
    let scale = Math.min(1, ww / div?.offsetWidth, wh / div?.offsetHeight);

    if (div?.offsetWidth == null) {
      setTimeout(() => resetScale(), 100);
      return;
    }

    // postMessage("window width: " + ww);
    // postMessage("div.offsetWidth: " + div?.offsetWidth);

    // postMessage("window height: " + wh);
    // postMessage("div.offsetHeight: " + div?.offsetHeight);

    // postMessage("width scale: " + ww / div?.offsetWidth);
    // postMessage("height scale: " + wh / div?.offsetHeight);
    // postMessage("scale: " + scale);

    const content = `width=device-width, initial-scale=${scale}, maximum-scale=${scale}, minimum-scale=${scale}`;
    viewport.setAttribute("content", content);

    const content2 = `width=device-width, initial-scale=${scale}, maximum-scale=10.0, minimum-scale=0.1`;
    viewport.setAttribute("content", content2);

    // postMessage("resetScale done");
    // postMessage(document.querySelector('meta[name="viewport"]').getAttribute("content"));
  };

  const renderChild = (parent) => {
    let result = data
      .filter((item) => item?.patron === parent?.recommendCode)
      .map((filteredItem) => {
        notLeaf.current.add(parent.recommendCode);
        return (
          <TreeNode
            key={filteredItem.recommendCode}
            label={<StyledNode>{{ ...filteredItem }}</StyledNode>}>
            {renderChild({ recommendCode: filteredItem.recommendCode })}
          </TreeNode>
        );
      });
    return result;
  };

  // const renderChild2 = (parent) => {
  //   if (data[0]?.recommendCode === parent.recommendCode) {
  //     data = [...data];
  //     postMessage("reset");
  //   }
  //   let node = data.find(
  //     (item, index) => item?.patron === parent?.recommendCode && data.splice(index, 1)
  //   );
  //   if (node == null) return;

  //   let nodeList = [];
  //   nodeList.push(node);

  //   let node2 = data.find(
  //     (item, index) => item?.patron === parent?.recommendCode && data.splice(index, 1)
  //   );

  //   if (node2) {
  //     nodeList.push(node2);
  //   }

  //   notLeaf.current.add(parent.recommendCode);

  //   let result = nodeList.map((item) => {
  //     return (
  //       <TreeNode key={item.recommendCode} label={<StyledNode>{{ ...item }}</StyledNode>}>
  //         {renderChild2({ recommendCode: item.recommendCode })}
  //       </TreeNode>
  //     );
  //   });

  //   return result;
  // };

  const StyledNode = ({ children }) => {
    let hasMoreChildren =
      !notLeaf.current.has(children.recommendCode) && children?.leftCnt + children?.rightCnt > 0;
    return (
      <button
        className="flex-col align-items-center"
        // className="flex-col align-items-center select-none"
        onClick={() => postMessage({ type: "RELOAD", recommendCode: children.recommendCode })}
        // {...showCode(children)}
      >
        <div
          className={`px-3 py-1 text-xs font-bold text-white ${
            levelColor[children?.level]
          } rounded-t-lg`}>
          {children.directRecommend && (
            <img className="w-2.5 mb-0.5 inline mr-1" alt="star" src="./img/star.png" />
          )}
          {children?.name}
        </div>
        <div className="px-3 py-1 text-xs text-slate-600 bg-slate-200 rounded-b-lg">
          {children?.recommendCode}
        </div>
        <div>{hasMoreChildren ? "⋮" : null}</div>
      </button>
    );
  };

  return (
    <body className="flex w-max min-w-full min-h-screen items-center justify-center">
      {data.length > 0 && (
        <div
          className="chart w-max p-4"
          style={{
            visibility: showChart ? "visible" : "hidden"
          }}>
          <Tree
            lineWidth={"3px"}
            lineColor={"#737373"}
            lineBorderRadius={"10px"}
            key={"root"}
            label={<StyledNode>{data[0]}</StyledNode>}>
            {renderChild({ recommendCode: data[0]?.recommendCode })}
          </Tree>
        </div>
      )}
    </body>
  );
}
